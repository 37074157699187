import React from "react"

import SEO from "../components/Seo"
import Layout from "../components/Layout"

import Portfolio from "../components/portfolio/Portfolio"

const PortfolioPage = () => {
  return (
    <Layout>
      <SEO
        title="نمونه کارها"
        description="در این صفحه تمام نمونه کارها وجود داره که بیشتر شامل نمونه های طراحی سایت شرکتی، طراحی سایت فروشگاهی، طراحی سایت خلاقانه، وب اپلیکیشن و کاتالوگ دیجیتال می شود"
      />

      <Portfolio />
    </Layout>
  )
}

export default PortfolioPage