import * as React from 'react';

// Daneshmand
import Cover1 from '../../assets/images/portfolio/chemical-cover.jpg';
import Complete1 from '../../assets/images/portfolio/chemical-complete.jpg';
// egsepehr
import Cover2 from '../../assets/images/portfolio/egsepehr-cover.jpg';
import Complete2 from '../../assets/images/portfolio/egsepehr-complete.jpg';
// Eliya
import Cover3 from '../../assets/images/portfolio/eliya-cover.jpg';
import Complete3 from '../../assets/images/portfolio/eliya-complete.jpg';
// Hyperarvin
import Cover4 from '../../assets/images/portfolio/hyperarvin-cover.jpg';
import Complete4 from '../../assets/images/portfolio/hyperarvin-complete.jpg';
// Abdol
import Cover5 from '../../assets/images/portfolio/abdolmalekico-cover.jpg';
import Complete5 from '../../assets/images/portfolio/abdolmalekico-complete.jpg';
// CanApply
import Cover6 from '../../assets/images/portfolio/canapply-cover.jpg';
import Complete6 from '../../assets/images/portfolio/canapply-complete.jpg';
// Coinbtltd
import Cover7 from '../../assets/images/portfolio/coinbtltd-cover.jpg';
import Complete7 from '../../assets/images/portfolio/coinbtltd-complete.jpg';
// Idehal
import Cover8 from '../../assets/images/portfolio/idehal-cover.jpg';
import Complete8 from '../../assets/images/portfolio/idehal-complete.jpg';
// Khorshidnet
import Cover9 from '../../assets/images/portfolio/khorshidnet-cover.jpg';
import Complete9 from '../../assets/images/portfolio/khorshidnet-complete.jpg';
// Khoshzabaan
import Cover10 from '../../assets/images/portfolio/khoshzabaan-cover.jpg';
import Complete10 from '../../assets/images/portfolio/khoshzabaan-complete.jpg';
// Manshour
import Cover11 from '../../assets/images/portfolio/manshour-cover.jpg';
import Complete11 from '../../assets/images/portfolio/manshour-complete.jpg';
// Meshkat
import Cover12 from '../../assets/images/portfolio/meshkat-cover.jpg';
import Complete12 from '../../assets/images/portfolio/meshkat-complete.jpg';
// Nojaan
import Cover13 from '../../assets/images/portfolio/nojaan-cover.jpg';
import Complete13 from '../../assets/images/portfolio/nojaan-complete.jpg';
// Petroshop
import Cover14 from '../../assets/images/portfolio/petroshop-cover.jpg';
import Complete14 from '../../assets/images/portfolio/petroshop-complete.jpg';
// Pouyanic
import Cover15 from '../../assets/images/portfolio/pouyanic-cover.jpg';
import Complete15 from '../../assets/images/portfolio/pouyanic-complete.jpg';

class Portfolio extends React.Component {
    render() {
        return (
            <section className="portfolio-wrapper page wrapper">
                <div className="container-fluid">
                    <div className="wrapper-title">
                        <span className="heading-meta">نمونه کارها</span>
                        <h2>نمونه کارهای انجام شده</h2>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <figure>
                                <img src={Cover14} alt="شرکت نفت خوزستان (پتروشاپ)" title="شرکت نفت خوزستان (پتروشاپ)" />
                                <figcaption>
                                    <a href={Complete14} target='_blank' rel="noopener noreferrer">
                                        <h5>نفت خوزستان (پتروشاپ)</h5>
                                        <p>سایت دایرکتوری آگهی نفت و حفاری</p>
                                        <i className="fas fa-arrow-circle-left"></i>
                                    </a>
                                </figcaption>
                            </figure>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <figure>
                                <img src={Cover9} alt="اینترنت خورشیدنت" title="اینترنت خورشیدنت" />
                                <figcaption>
                                    <a href={Complete9} target='_blank' rel="noopener noreferrer">
                                        <h5>اینترنت خورشیدنت</h5>
                                        <p>یکی از ISP های مطرح کشور</p>
                                        <i className="fas fa-arrow-circle-left"></i>
                                    </a>
                                </figcaption>
                            </figure>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <figure>
                                <img src={Cover6} alt="مهاجرت به کانادا (کن اپلای)" title="مهاجرت به کانادا (کن اپلای)" />
                                <figcaption>
                                    <a href={Complete6} target='_blank' rel="noopener noreferrer">
                                        <h5>کن اپلای</h5>
                                        <p>شرکت ارائه دهنده خدمات مهاجرت به کانادا</p>
                                        <i className="fas fa-arrow-circle-left"></i>
                                    </a>
                                </figcaption>
                            </figure>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <figure>
                                <img src={Cover8} alt="نمایندگی پاناسونیک" title="نمایندگی پاناسونیک" />
                                <figcaption>
                                    <a href={Complete8} target='_blank' rel="noopener noreferrer">
                                        <h5>نمایندگی پاناسونیک</h5>
                                        <p>سایت فروشگاهی برای نمایندگی برند پاناسونیک</p>
                                        <i className="fas fa-arrow-circle-left"></i>
                                    </a>
                                </figcaption>
                            </figure>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <figure>
                                <img src={Cover3} alt="ثبت شرکت ایلیا" title="ثبت شرکت ایلیا" />
                                <figcaption>
                                    <a href={Complete3} target='_blank' rel="noopener noreferrer">
                                        <h5>ثبت شرکت ایلیا</h5>
                                        <p>سایت شرکتی سئو محور</p>
                                        <i className="fas fa-arrow-circle-left"></i>
                                    </a>
                                </figcaption>
                            </figure>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <figure>
                                <img src={Cover11} alt="آموزشگاه منشور دانش" title="آموزشگاه منشور دانش" />
                                <figcaption>
                                    <a href={Complete11} target='_blank' rel="noopener noreferrer">
                                        <h5>آموزشگاه منشور دانش</h5>
                                        <p>آموزشگاه کنکور منشور دانش</p>
                                        <i className="fas fa-arrow-circle-left"></i>
                                    </a>
                                </figcaption>
                            </figure>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <figure>
                                <img src={Cover13} alt="تصفیه هوای نوجان" title="تصفیه هوای نوجان" />
                                <figcaption>
                                    <a href={Complete13} target='_blank' rel="noopener noreferrer">
                                        <h5>تصفیه هوای نوجان</h5>
                                        <p>سایت شرکتی تولیدکننده دستگاه های تصفیه هوا</p>
                                        <i className="fas fa-arrow-circle-left"></i>
                                    </a>
                                </figcaption>
                            </figure>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <figure>
                                <img src={Cover1} alt="پتروشیمی دانشمند" title="پتروشیمی دانشمند" />
                                <figcaption>
                                    <a href={Complete1} target='_blank' rel="noopener noreferrer">
                                        <h5>پتروشیمی دانشمند</h5>
                                        <p>سایت شرکتی ۴ زبانه، دارای کاتالوگ دیجیتال</p>
                                        <i className="fas fa-arrow-circle-left"></i>
                                    </a>
                                </figcaption>
                            </figure>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <figure>
                                <img src={Cover12} alt="دبیرستان مشکات" title="دبیرستان مشکات" />
                                <figcaption>
                                    <a href={Complete12} target='_blank' rel="noopener noreferrer">
                                        <h5>دبیرستان مشکات</h5>
                                        <p>سایت شرکتی دبیرستان غیرانتفاعی</p>
                                        <i className="fas fa-arrow-circle-left"></i>
                                    </a>
                                </figcaption>
                            </figure>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <figure>
                                <img src={Cover10} alt="آموزشگاه خوش زبان" title="آموزشگاه خوش زبان" />
                                <figcaption>
                                    <a href={Complete10} target='_blank' rel="noopener noreferrer">
                                        <h5>آموزشگاه خوش زبان</h5>
                                        <p>سایت فروشگاهی برای آموزشگاه زبان</p>
                                        <i className="fas fa-arrow-circle-left"></i>
                                    </a>
                                </figcaption>
                            </figure>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <figure>
                                <img src={Cover15} alt="پویانیک بنیان شریف" title="پویانیک بنیان شریف" />
                                <figcaption>
                                    <a href={Complete15} target='_blank' rel="noopener noreferrer">
                                        <h5>پویانیک بنیان شریف</h5>
                                        <p>سایت شرکتی برای تولیدکننده مدارهای الکترونیکی</p>
                                        <i className="fas fa-arrow-circle-left"></i>
                                    </a>
                                </figcaption>
                            </figure>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <figure>
                                <img src={Cover7} alt="صرافی آنلاین Coinbtltd" title="صرافی آنلاین Coinbtltd" />
                                <figcaption>
                                    <a href={Complete7} target='_blank' rel="noopener noreferrer">
                                        <h5>صرافی آنلاین Coinbtltd</h5>
                                        <p>سایت صرافی آنلاین کریپتوکارنسی</p>
                                        <i className="fas fa-arrow-circle-left"></i>
                                    </a>
                                </figcaption>
                            </figure>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <figure>
                                <img src={Cover2} alt="انطباق گستر سپهر" title="انطباق گستر سپهر" />
                                <figcaption>
                                    <a href={Complete2} target='_blank' rel="noopener noreferrer">
                                        <h5>انطباق گستر سپهر</h5>
                                        <p>سایت سه زبانه، دارای کاتالوگ دیجیتال</p>
                                        <i className="fas fa-arrow-circle-left"></i>
                                    </a>
                                </figcaption>
                            </figure>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <figure>
                                <img src={Cover4} alt="هایپر ساختمانی آروین" title="هایپر ساختمانی آروین" />
                                <figcaption>
                                    <a href={Complete4} target='_blank' rel="noopener noreferrer">
                                        <h5>هایپر ساختمانی آروین</h5>
                                        <p>سایت شرکتی، دارای کاتالوگ دیجیتال</p>
                                        <i className="fas fa-arrow-circle-left"></i>
                                    </a>
                                </figcaption>
                            </figure>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <figure>
                                <img src={Cover5} alt="ماشین سازی عبدالملکی" title="ماشین سازی عبدالملکی" />
                                <figcaption>
                                    <a href={Complete5} target='_blank' rel="noopener noreferrer">
                                        <h5>ماشین سازی عبدالملکی</h5>
                                        <p>سایت شرکتی در زمینه تولید ماشین آلات صنعتی</p>
                                        <i className="fas fa-arrow-circle-left"></i>
                                    </a>
                                </figcaption>
                            </figure>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default Portfolio;